import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import $ from 'jquery'
import './main.scss'

const initializeFullCalendar = () =>
  $('.upcoming-events-calendar--uninitialized').each(function () {
    const $this = $(this)

    const currentYear = new Date().getFullYear()
    let occurrences = $this.data('occurrences')
    const firstDayOfWeek = $this.data('first-day-of-week')

    occurrences = occurrences.map((occurrence) => ({
      id: occurrence.id,
      group_id: occurrence.event_id || undefined,
      title: occurrence.title,
      url: occurrence.show_url,
      start: new Date(occurrence.starts_at_unix * 1000),
      end: new Date(occurrence.ends_at_unix * 1000),
    }))

    const noOfWeeksToShow = $this.data('no-of-weeks')
    const noVerticalScroll = $this.data('no-vertical-scroll')

    const height = noVerticalScroll || noOfWeeksToShow <= 5 ? 'auto' : undefined

    let calendar = new Calendar(this, {
      plugins: [dayGridPlugin],
      initialView: 'multiMonth',
      selectable: true,
      firstDay: firstDayOfWeek,
      headerToolbar: false,
      events: occurrences,
      eventDisplay: 'list-item',
      dateIncrement: { weeks: 1 },

      eventClick: function (info) {
        info.jsEvent.preventDefault()

        if (info.event.url) {
          window.open(info.event.url)
        }
      },
      views: {
        multiMonth: {
          type: 'dayGrid',
          duration: { weeks: noOfWeeksToShow },
          eventTimeFormat: {
            hour: 'numeric',
            minute: '2-digit',
            omitZeroMinute: true,
            meridiem: 'lowercase',
          },
        },
      },
      height,
      dayCellDidMount(day) {
        const { date } = day
        if (date.getDate() === 1) {
          return day.el.classList.add('start-of-month-cell')
        }
      },
    })

    calendar.render()
    const calendarContainer = $this.parent()
    const scrollUpBtn = calendarContainer.find('.scroll-up')
    scrollUpBtn.on('click', () => {
      calendar.prev()
    })
    const scrollDownBtn = calendarContainer.find('.scroll-down')
    scrollDownBtn.on('click', () => {
      calendar.next()
    })
    $this.removeClass('upcoming-events-calendar--uninitialized')

    return $(document).on('simplero:section:unload', () => (calendar = null))
  })

$(document).on('simplero:section:load', initializeFullCalendar)
$(initializeFullCalendar)
